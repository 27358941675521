import { FC, ReactNode } from 'react';

import styled from '@emotion/styled';

interface PageProps {
	hasBackground?: boolean;
	children: ReactNode;
}

const Container = styled('div')<PageProps>`
	background-color: #e5e5e5;
	overflow: auto;
`;

export const Page: FC<PageProps> = ({ children, hasBackground = true }) => (
	<Container hasBackground={hasBackground}>{children}</Container>
);
