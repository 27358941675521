import { FC, ReactNode } from 'react';

import styled from '@emotion/styled';
import { Circle } from 'components/Circle';

import { Icon, IconKeys } from '@fllite-fe/shared/src/components/Icon';
import { Paragraph } from '@fllite-fe/shared/src/components/Text';

interface BenefitIconProps {
	order?: number;
}

const BenefitContainer = styled('div')`
	display: flex;
	align-items: center;
	max-width: 450px;
`;

const BenefitIcon = styled(Icon)<BenefitIconProps>`
	width: ${({ order }) => (order === 1 ? '36px' : order === 2 ? '50px' : '60px')};
	position: relative;
	top: ${({ order }) => (order === 3 ? '1px' : order === 2 ? '2px' : '4px')};
	left: ${({ order }) => (order === 1 ? '-1px' : order === 2 ? '5px' : '11px')};

	.svg {
		${({ order }) => (order === 1 ? '36px' : order === 2 ? '50px' : '60px')};
	}
`;

const CircleContainer = styled('div')`
	position: relative;
	width: 120px;
	height: 120px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	margin-right: 10px;
`;

const StyledCircle = styled(Circle)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	pointer-events: none;
`;

const StyledParagraph = styled(Paragraph)`
	font-weight: 500;
`;

interface BenefitProps {
	icon: IconKeys;
	order: number;
	children: ReactNode;
}

export const Benefit: FC<BenefitProps> = ({ icon, children, order }) => (
	<BenefitContainer>
		<CircleContainer>
			<BenefitIcon order={order} type={icon} />
			<StyledCircle radius={60} pulsing />
		</CircleContainer>
		<StyledParagraph>{children}</StyledParagraph>
	</BenefitContainer>
);
