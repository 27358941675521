import { FC, ReactNode, useMemo } from 'react';

import styled from '@emotion/styled';
import { Page } from 'components/Page';
import { useRouter } from 'next/router';

import { Layout as CommonLayout } from '@fllite-fe/shared/src/components/Layout';
import { H4 } from '@fllite-fe/shared/src/components/Text';

import { Benefit } from './Benefit';

const PageWrapper = styled('div')`
	background: url('/assets/runaway2_1280.webp');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-color: #022366;

	${({ theme }) => theme.breakpoints.up('md')} {
		overflow: hidden;
	}

	${({ theme }) => theme.breakpoints.up('lg')} {
		background: url('/assets/runaway2.webp');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
`;

const Container = styled('div')`
	min-width: 100vw;

	${({ theme }) => theme.breakpoints.up('md')} {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100vh;
	}
`;

const ModalContainer = styled('div')``;

const ModalBase = styled('div')`
	width: 100%;
	${({ theme }) => theme.breakpoints.up('md')} {
		max-width: 715px;
	}
`;

const StandardModal = styled(ModalBase)`
	padding: 24px;
	background: #ffffff;
	box-shadow: ${({ theme }) => theme.shadow.popup};

	${({ theme }) => theme.breakpoints.up('md')} {
		border-radius: ${({ theme }) => theme.radius.boxRadius};
	}
`;

const SignUpModal = styled(ModalBase)`
	padding: 24px;
	background: #ffffff;
	box-shadow: ${({ theme }) => theme.shadow.popup};

	${({ theme }) => theme.breakpoints.up('md')} {
		border-radius: ${({ theme }) => theme.radius.boxRadius};
	}
`;

const TransparentModal = styled(ModalBase)`
	background-color: rgba(255, 255, 255, 0);
`;

const BenefitsContent = styled('div')`
	width: 100%;
	padding: 0 15px;

	${({ theme }) => theme.breakpoints.up('md')} {
		max-width: ${({ theme }) =>
			`calc(${theme.dimensions.signUpWidth} / 2 + ${theme.dimensions.formWidthPortionXl})`};
	}
`;

const BenefitsContainer = styled('div')`
	display: none;

	${({ theme }) => theme.breakpoints.up('md')} {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		height: 100%;
	}

	${({ theme }) => theme.breakpoints.up('lg')} {
		margin-right: 80px;
	}
`;

const StyledH4 = styled(H4)`
	margin-bottom: 45px;
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
	max-width: 393px;
`;

interface LayoutProps {
	children: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
	const { pathname } = useRouter();

	const modal = useMemo(() => {
		if (pathname === '/welcome') {
			return <TransparentModal>{children}</TransparentModal>;
		}
		if (pathname === '/register') {
			return <SignUpModal>{children}</SignUpModal>;
		}

		return <StandardModal>{children}</StandardModal>;
	}, [pathname]);

	return (
		<CommonLayout>
			<Page hasBackground={false}>
				<PageWrapper>
					<Container>
						<BenefitsContainer>
							<BenefitsContent>
								<StyledH4 data-testid="h4-welcome">
									Welcome to Fllite Ops where you can enjoy unique advantages:
								</StyledH4>
								<Benefit order={1} icon="secure">
									Protect and secure your data, banking details and privacy
								</Benefit>
								<Benefit order={2} icon="revenue">
									Get access to the Fllite community and increase your revenue!
								</Benefit>
								<Benefit order={3} icon="booking">
									Manage bookings seamlessly with our exclusive algorithm and intuitive interface
								</Benefit>
							</BenefitsContent>
						</BenefitsContainer>
						<ModalContainer>{modal}</ModalContainer>
					</Container>
				</PageWrapper>
			</Page>
		</CommonLayout>
	);
};
